<template>

<page :itle="`New ${Naming.Zone}`">

  <form class="box">
    <div class="columns">
      <div class="column">
        <text-input :value="zone.name" @input="name" :error="$root.errors.name">Name</text-input>
      </div>
      <div class="column">
        <select-input 
          :error="$root.errors.type_id" 
          :items="assetGroupList" 
          label-key="description" 
          value-key="id" 
          :value="zone.type_id" 
          @input="type">{{ Naming.AssetGroup }}</select-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <submit-button class="is-success" :working="working" @submit="createZone" left-icon="check">Create {{ Naming.Zone }}</submit-button>
      </div>
    </div>
  </form>
</page>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    working: false
  }),

  beforeCreate() {
    this.$store.commit('zone/clearZone')
  },

  created() {
    this.$store.dispatch('assetGroup/loadAssetGroupList')
  },

  beforeDestroy() {
    this.clear()
  },

  methods: {
    ...mapMutations('zone', [
      'name',
      'size',
      'setSite',
      'clear',
      'type'
    ]),
    createZone() {
      this.working = true
      this.setSite(this.$route.params.site)

      this.$store.dispatch('zone/createZone').then(zone => {
        this.working = false
        this.$toast.success(this.Convert(this.$lang.zone.created))
        this.$router.push({
          name: 'site-zones',
          params: {
            site: this.$route.params.site
          }
        })

      }).catch(error => {
        this.working = false
        this.$whoops()
      })
    }
  },

  computed: {
    ...mapGetters('zone', [
      'zone'
    ]),
    ...mapGetters('assetGroup', [
      'assetGroupList'
    ])
  }
}
</script>
