<template>
  <div class="box">
    <div class="columns">
      <div class="column">

        <h4 class="title is-5">Transfer</h4>
        <p>
          {{ Naming.Zones }} may be transferred to another {{ Naming.Site.toLowerCase() }} or even {{ Naming.Client.toLowerCase() }}. Please note that the receiving
          {{ Naming.Site.toLowerCase() }} must have the appropriate {{ Naming.Zones.toLowerCase() }}/{{ Naming.AssetGroups.toLowerCase() }} configured.
        </p>

        <action-button
            :disabled="! $root.hasAbility('transfer-zones', 'Billow\\Models\\Site\\Zone')"
            class="is-warning is-medium mt-1" left-icon="exchange-alt" @click="selectSite">
          Initiate Transfer
        </action-button>

      </div>
    </div>
  </div>
</template>

<script>
import { transferZone } from '@/modals'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('zone', [
      'zone'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
  },

  methods: {
    selectSite() {
      transferZone(this.zone)
    },
  },

  created() {
    this.$store.dispatch('zone/loadZone', this.$route.params.zone)
    this.$store.dispatch('site/loadSite', this.$route.params.site)
  },
}
</script>
