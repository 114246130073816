<template>
<div>
  <form>
    <div class="columns">
      <div class="column">
        <text-input :error="$root.errors.name" :value="zone.name" @input="name">Name</text-input>
      </div>
      <div class="column">
        <select-input 
          :error="$root.errors.type_id" 
          :items="assetGroupList" 
          label-key="description" 
          value-key="id" 
          :value="zone.type_id"
          @input="type">
          {{ Naming.AssetGroup }}
        </select-input>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <submit-button class="is-success" @submit="save">Save</submit-button>
      </div>
      <div v-if="isAdmin" class="column has-text-right">
        <action-button class="is-danger" @click="deleteZone" left-icon="trash">delete</action-button>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {

  created() {
    this.$store.dispatch('assetGroup/loadAssetGroupList')
  },

  methods: {
    ...mapMutations('zone', [
      'name',
      'size',
      'type'
    ]),
    save() {
      this.$store.dispatch('zone/saveZone').then(
        () => this.$toast.success(this.Convert(this.$lang.zone.saved))
      ).catch(
        error => this.$whoops()
      )
    },
    async deleteZone() {
      const { title, message, confirmText } = this.$lang.zone.confirmDelete;
      const convertedTitle = await this.Convert(title);
      const convertedMessage = await this.Convert(message);
      const convertedConfirmText = await this.Convert(confirmText);

      if (await this.$confirm({
        title: convertedTitle,
        message: convertedMessage,
        confirmText: convertedConfirmText,
        confirmClass: 'is-danger'
      })) {
        this.$store.dispatch('zone/deleteZone').then(() => {
          this.$toast.success(this.Convert(this.$lang.zone.deleted));
          this.$router.push({ name: 'site-overview', params: this.zone.site_id });
        }).catch(error => {
          console.error(error);
          this.$whoops();
        });
      }
    },
  },

  computed: {
    ...mapGetters('zone', [
      'zone'
    ]),
    ...mapGetters('assetGroup', [
      'assetGroupList'
    ])
  }

}
</script>
