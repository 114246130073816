<template>
<page icon="bezier-curve" :title="zone.name">
  <div slot="tools">
    <div class="tabs is-toggle">
      <ul>
        <router-link exact tag="li" :to="{ name: 'site-overview', params: { site: this.$route.params.site }}">
          <a>
            <icon icon="city"/>
            <span>{{ Naming.Site }}</span>
          </a>
        </router-link>
        <router-link exact tag="li" :to="{ name: 'zone-overview', params: { zone: this.$route.params.zone }}">
          <a>
            <icon icon="chart-area"/>
            <span>{{ Naming.Assets }}</span>
          </a>
        </router-link>
        <router-link v-if="isAdmin" exact tag="li" :to="{ name: 'zone-tools', params: { zone: this.$route.params.zone }}">
          <a>
            <icon icon="tools"/>
            <span>Tools</span>
          </a>
        </router-link>
        <router-link v-if="isAdmin" exact tag="li" :to="{ name: 'zone-edit', params: { zone: this.$route.params.zone }}">
          <a>
            <icon icon="pencil-alt"/>
            <span>Edit Information</span>
          </a>
        </router-link>
      </ul>
    </div>
  </div>
  <router-view class="box" />
</page>  
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  created() {
    this.$store.dispatch('zone/loadZone', this.$route.params.zone)
    this.$store.dispatch('site/loadSite', this.$route.params.site)
  },

  computed: {
    ...mapGetters('zone', [
      'zone'
    ]),
    ...mapGetters('site', [
      'site'
    ]),
    ...mapGetters('auth', [
      'isAdmin'
    ])
  }

}
</script>
