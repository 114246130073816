<template>
<div>
  <form class="mb-1" >
    <text-input
      classes="is-rounded"
      v-model="filters.search"
      :placeholder="`Search by ${Naming.Asset.toLowerCase()} code or barcode…`"
      left-icon="search"
      :label="false">
      <action-button
        @click="runSearch"
        class="is-rounded is-cool-blue-dark"
        slot="right">
        Search
      </action-button>
    </text-input>
  </form>
  <pager :pageable="assets" @nav="goToPage" :context="Naming.Assets.toLowerCase()"/>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>{{ Naming.Asset }} / Barcode</th>
        <th>Risk Level</th>
        <th>Last {{ Naming.Inspection }}</th>
        <th>Next {{ Naming.Inspection }}</th>
        <th>{{ Naming.AssetType }}</th>
        <th>Current Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="asset in assets.data" :key="asset.id">
        <td>
          <link-asset-manager :asset="asset" :site-id="$route.params.site"/>
          <br>
          <span v-if="asset.barcode">{{ asset.barcode.data }}</span>
        </td>
        <td>
          {{ asset.risk.level }}
        </td>
        <td>
          <latest-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></latest-inspection>
        </td>
        <td>
          <next-inspection :checklist-inspection-dates="asset.checklist_inspection_dates"></next-inspection>
        </td>
        <td>
          {{ asset.type.name }}
        </td>
        <td class="is-narrow">
          <current-status :status="asset.current_status"/><br>
          <component-icon :asset="asset"/>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="columns">
    <div class="column has-text-centered">
      <p v-if="!assets.data.length">No {{ Naming.Assets.toLowerCase() }} Found. Start by opening a new {{ Naming.Job.toLowerCase() }} to add {{ Naming.Assets.toLowerCase() }} to this {{ Naming.Site.toLowerCase() }}.</p>
    </div>
  </div>

  <pager :pageable="assets" @nav="goToPage" :context="Naming.Assets.toLowerCase()"/>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  data: () => ({
    filters: {
      search: ''
    }
  }),

  created() {
    if(this.$route.query.search) {
      this.filters.search = this.$route.query.search
    }
    this.loadAssets()
  },

  destroyed() {
    this.$store.commit('asset/clear')
  },

  methods: {
    loadAssets() {
      this.$store.dispatch(
        'asset/loadAssetsByZone',
        {
          path: 'api' + this.$route.fullPath,
          params: this.filters
        }
      )
    },
    runSearch() {
      this.$router.push({
        name: 'zone-overview',
        params: {
          site: this.$route.params.site,
          zone: this.$route.params.zone,
        },
        query: this.filters
      })
    },
    goToPage(path) {
      this.$router.push(path)
    }
  },

  computed: {
    ...mapGetters('client', [
      'client'
    ]),
    ...mapGetters('asset', [
      'assets'
    ])
  },

  watch: {
    '$route': 'loadAssets'
  }

}
</script>
